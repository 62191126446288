const featuredHomestyles = {
  wrapper: {
    bg: "headerBg",
    img: {
      maxWidth: "100%",
      width: "100%",
    },
  },
  container: {
    variant: "variants.featHome",
    maxWidth: ["1440px"],
    px: [3, 4, null],
    py: [4, 5, 6],
    h1: {
      color: "#c8ccc0",
      fontSize: [5, 5, 5, 6],
      lineHeight: ["130%"],
      fontWeight: "100",
      mb: "0",
      strong: {
        fontWeight: [700, 700, 700, 900],
        color: "#95b552",
      },
    },
    h2: {
      color: "homeBodyText",
      fontWeight: "300",
      fontSize: [3, 3, 3, 4],
      mt: 4,
    },
  },
  imageWrap: {
    position: "relative",
    display: "block",
    transition: "all .16s linear",
    img: {
      display: "block",
      background: "#fff",
      borderRadius: "16px",
    },
    color: "homeHeadingText",
    ".featButton": {
      display: "flex",
      alignItems: "center",
      color: "#D7FF2490",
    },
    "&:hover": {
      color: "#fff",
      ".featButton": {
        color: "#D7FF24",
      },
    },
  },
  titleCard: {
    display: "flex",
    justifyContent: "space-between",
    pt: 6,
    pb: 3,
    px: 3,
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
    borderRadius: "0 0 16p 16px",
    fontSize: 2,
    lineHeight: "130%",
  },
  button: {
    bg: "#c3ceaa",
    color: "headerBg",
    fontWeight: "600",
    px: [4, 4, 4, 4],
    py: [3, 3, 3, 4],
    mt: [3, 4],
    transition: ".3s background linear",
    "&:hover": {
      bg: "#fff",
      color: "headerBg",
    },
  },
  buttonNone: {
    bg: "#00000000",
    color: "c3ceaa",
    fontWeight: "600",
    px: [4],
    py: [3, 3, 3, 4],
    mt: [3, 4],
    transition: ".3s background linear",
    "&:hover": {
      color: "#fff",
      textDecoration: "underline"
    },
  },
}

export default featuredHomestyles
