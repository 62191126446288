/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import SEO from "../components/seo"
import ProductsList from "../components/products-list"
import "../assets/scss/blog-post.scss"
import FeaturedHome from "../components/featured-home"
import PromoCard from "../components/promo-card"

export const query = graphql`
  query {
    productCount: allStrapiProducts {
      totalCount
    }
    featuredProduct: allStrapiProducts(
      filter: { productType: { elemMatch: { price: { gt: 0 } } } }
      limit: 1
      sort: { order: DESC, fields: published_at }
    ) {
      edges {
        node {
          id
          title
          slug
          prefix
          meta_description
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    latestProducts: allStrapiProducts(
      limit: 5
      sort: { order: DESC, fields: published_at }
    ) {
      totalCount
      edges {
        node {
          id
          created_at
          updated_at
          slug
          prefix
          title
          meta_description
          productType {
            githubURL
            snipcartGUID
            price
          }
          styling {
            title
          }
          liveDemo
          headlessCMS {
            title
          }
          ssg {
            title
          }
          author {
            title
            slug
          }
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                breakpoints: [416, 832, 1248, 1664]
              )
            }
          }
        }
      }
    }
    editorChoiceProducts: allStrapiProducts(
      limit: 3
      filter: {editorChoice: {eq: true}},
      sort: { order: DESC, fields: published_at }
    ) {
      totalCount
      edges {
        node {
          id
          created_at
          updated_at
          slug
          prefix
          title
          meta_description
          productType {
            githubURL
            snipcartGUID
            price
          }
          styling {
            title
          }
          liveDemo
          headlessCMS {
            title
          }
          ssg {
            title
          }
          author {
            title
            slug
          }
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                breakpoints: [416, 832, 1248, 1664]
              )
            }
          }
        }
      }
    }
    latestPosts: allStrapiPosts(
      limit: 6
      sort: { order: DESC, fields: published_at }
    ) {
      totalCount
      edges {
        node {
          id
          published_at(formatString: "MMMM DD, YYYY")
          Title
          Slug
          prefix
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { 
    productCount, 
    featuredProduct, 
    latestProducts, 
    latestPosts,
    editorChoiceProducts
  } = data
  return (
    <Layout>
      <SEO
        title="Stackrole — Jamstack templates for Startups and Professionals"
        description="Empowering Startups and Professionals, to launch their blazing fast Jamstack website"
      />
      <div sx={indexStyles.featured}>
        <FeaturedHome productCount={productCount.totalCount} />
        <PromoCard data={featuredProduct} />
        <Box sx={indexStyles.container}>
          <section sx={indexStyles.products}>
            <ProductsList data={latestProducts} isHome={true} showPosts="5" />
          </section>
          <section sx={indexStyles.products}>
            <ProductsList data={editorChoiceProducts} isHome={false}  editorChoice={true} showPosts="3" />
          </section>
          <section sx={indexStyles.blogSection}>
            <BlogListHome data={latestPosts} />
          </section>
        </Box>
      </div>
    </Layout>
  )
}

const indexStyles = {
  featured: {
    backgroundColor: "coverBackground",
  },
  container: {
    variant: "variants.container",
  },
  products: {
    pt: [3, 3, null, null, null],
    pb: 5,
  },
  blogSection: {
    pb: 5,
  },
}

export default HomePage
