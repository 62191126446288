/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

const ViewAllButton = ({ to, children }) => (
  <Link
    to={to}
    sx={{
      position: "relative",
      display: "grid",
      justify: "center",
      alignItems: "center",
      fontWeight: "bold",
      fontSize: 4,
      py: [3],
      px: [4],
      textAlign: "center",
      variant: "cards.primary",
      color: "#444",
      backgroundColor: "#f9f9f9",
      ":hover": {
        backgroundColor: "#fff",
        boxShadow: "0 0 1px #ccc",
      },
    }}
  >
    {children}
  </Link>
)

export default ViewAllButton
