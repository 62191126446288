/** @jsx jsx */
import { jsx, Grid } from "theme-ui"
import React from "react"
import { RiArrowRightLine } from "react-icons/ri"

import ViewAllButton from "./view-all"
import PostCard from "./post-card"

const PostMaker = ({ allPosts, postCount, showPosts }) => (
  <React.Fragment>
    <h2>
      In <b>Blog</b>
    </h2>
    <Grid columns={[1, 2, 3]} sx={{ gridGap: 4 }}>
      {allPosts}
      {postCount > showPosts ? (
        <ViewAllButton to="/blog/">
          <span>
            View more <RiArrowRightLine className="icon -right" />
          </span>
        </ViewAllButton>
      ) : (
        ""
      )}
    </Grid>
  </React.Fragment>
)

export default function BlogListHome(props) {
  const posts = props.data.edges
  const postCount = props.data.totalCount
  const showPosts = 5
  const allPosts = posts.slice(0, showPosts).map(edge => {
    const props = {
      slug: edge.node.Slug,
      prefix: edge.node.prefix,
      date: edge.node.published_at,
      title: edge.node.Title,
    }
    return <PostCard key={edge.node.id} data={props} />
  })
  return (
    <PostMaker
      allPosts={allPosts}
      postCount={postCount}
      showPosts={showPosts}
    />
  )
}
