/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { RiArrowRightLine } from "react-icons/ri"
import LinkButton from "./link-button"

import featuredHomestyles from "../components/featured-home.styles"

export default function FeaturedHome(props) {
  const url = "/building-jamstack-website/"
  return (
    <div sx={featuredHomestyles.wrapper}>
      <Box sx={featuredHomestyles.container}>
        <div>
          <h1>
            Empowering <br />
            <strong>Startups</strong> and <strong>Professionals</strong>, <br />
            to launch their blazing fast <strong>Jamstack</strong> website
          </h1>
          <h2>Choose from {props.productCount}+ Jamstack website templates</h2>
          <LinkButton
            to="https://app.netlify.com/start/deploy?repository=https://github.com/stackrole/gatsby-starter-foundation"
            className="button"
            target="_blank"
            sx={featuredHomestyles.button}
            title="Create your website with Foundation Starter"
          >
            Create a Website
            <span className="icon -right">
              <RiArrowRightLine />
            </span>
          </LinkButton>
          <LinkButton
            to={url}
            className="button"
            sx={featuredHomestyles.buttonNone}
            title="Learn how you can create a Jamstack website for Free"
          >
            How it works?
          </LinkButton>
        </div>
      </Box>
    </div>
  )
}
